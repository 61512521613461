import React from "react"

import BaseStructure from "../components/site/baseStructure"
import Seo from "../components/site/seo"
import PageHeader from "../components/elements/pageHeader"

import Container from "@material-ui/core/Container"

const AboutPage = ({ location }) => {
  return (
    <BaseStructure location={location}>
      <Seo title="Terms & Conditions" />
      <Container className="standardWidth">
        <PageHeader title="Terms & Conditions" />
        <p>Last updated: October 25, 2020</p>

        <p>
          The information contained on https://namawalks.com website (the
          “Service”) is for general information purposes only.
        </p>

        <p>
          The maintainers assume no responsibility for errors or omissions in
          the contents on the Service.
        </p>

        <p>
          In no event shall the authors be liable for any special, direct,
          indirect, consequential, or incidental damages or any damages
          whatsoever, whether in an action of contract, negligence or other
          tort, arising out of or in connection with the use of the Service or
          the contents of the Service. The authors reserve the right to make
          additions, deletions, or modification to the contents on the Service
          at any time without prior notice.
        </p>

        <p>
          The maintainers do not warrant that the Service is free of viruses or
          other harmful components.
        </p>

        <h2>Intellectual property rights</h2>

        <p>
          This Agreement does not transfer to you any intellectual property
          owned by Website Operator or third-parties, and all rights, titles,
          and interests in and to such property will remain (as between the
          parties) solely with Website Operator. All trademarks, service marks,
          graphics and logos used in connection with our Website or Services,
          are trademarks or registered trademarks of Website Operator or Website
          Operator licensors. Other trademarks, service marks, graphics and
          logos used in connection with our Website or Services may be the
          trademarks of other third-parties. Your use of our Website and
          Services grants you no right or license to reproduce or otherwise use
          any Website Operator or third-party trademarks.
        </p>

        <h2>Affiliate disclaimer</h2>

        <p>
          This affiliate disclosure details the affiliate relationships of the
          maintainers with other companies and products.
        </p>

        <p>
          Some of the links are “affiliate links”, a link with a special
          tracking code. This means if you click on an affiliate link and
          purchase the item, we will receive an affiliate commission.
        </p>

        <p>
          The price of the item is the same whether it is an affiliate link or
          not. Regardless, we only recommend products or services we believe
          will add value to our readers.
        </p>

        <p>
          By using the affiliate links, you are helping support the Service, and
          we genuinely appreciate your support.
        </p>

        <p>Affiliate advertising programs that the Service uses are:</p>

        <ul>
          <li>
            Amazon Services LLC Associates Program: As an Amazon Associate, I
            earn from qualifying purchases. namawalks.com is a participant in
            the Amazon Services LLC Associates Program, an affiliate advertising
            program designed to provide a means for sites to earn advertising
            fees by advertising and linking to Amazon.com. Pages on this Service
            may include affiliate links to Amazon and its affiliate sites on
            which the owner of this Service, namawalks, will make a referral
            commission.{" "}
          </li>
        </ul>

        <h2>External links disclaimer</h2>

        <p>
          https://namawalks.com website may contain links to external websites
          that are not provided or maintained by or in any way affiliated with
          us.
        </p>

        <p>
          Please note that we do not guarantee the accuracy, relevance,
          timeliness, or completeness of any information on these external
          websites.
        </p>

        <h2>Advertisements</h2>

        <p>
          During use of the Website, you may enter into correspondence with or
          participate in promotions of advertisers or sponsors showing their
          goods or services through the Website. Any such activity, and any
          terms, conditions, warranties or representations associated with such
          activity, is solely between you and the applicable third-party. We
          shall have no liability, obligation or responsibility for any such
          correspondence, purchase or promotion between you and any such
          third-party.
        </p>

        <h2>Limitation of liability</h2>

        <p>
          To the fullest extent permitted by applicable law, in no event will
          Website Operator, its affiliates, officers, directors, employees,
          agents, suppliers or licensors be liable to any person for (a): any
          indirect, incidental, special, punitive, cover or consequential
          damages (including, without limitation, damages for lost profits,
          revenue, sales, goodwill, use or content, impact on business, business
          interruption, loss of anticipated savings, loss of business
          opportunity) however caused, under any theory of liability, including,
          without limitation, contract, tort, warranty, breach of statutory
          duty, negligence or otherwise, even if Website Operator has been
          advised as to the possibility of such damages or could have foreseen
          such damages. To the maximum extent permitted by applicable law, the
          aggregate liability of Website Operator and its affiliates, officers,
          employees, agents, suppliers and licensors, relating to the services
          will be limited to an amount greater of one dollar or any amounts
          actually paid in cash by you to Website Operator for the prior one
          month period prior to the first event or occurrence giving rise to
          such liability. The limitations and exclusions also apply if this
          remedy does not fully compensate you for any losses or fails of its
          essential purpose.
        </p>

        <h2>Changes and amendments</h2>

        <p>
          We reserve the right to modify this Agreement or its policies relating
          to the Website or Services at any time, effective upon posting of an
          updated version of this Agreement on the Website. When we do, we will
          revise the updated date at the bottom of this page. Continued use of
          the Website after any such changes shall constitute your consent to
          such changes. Policy was created with{" "}
          <a
            target="_blank"
            href="https://www.websitepolicies.com/terms-and-conditions-generator"
            rel="noreferrer noopener external"
            data-wpel-link="external"
          >
            WebsitePolicies.com
          </a>
        </p>

        <h2>Acceptance of these terms</h2>

        <p>
          You acknowledge that you have read this Agreement and agree to all its
          terms and conditions. By using the Website or its Services you agree
          to be bound by this Agreement. If you do not agree to abide by the
          terms of this Agreement, you are not authorized to use or access the
          Website and its Services.
        </p>

        <h2>Contacting us</h2>

        <p>
          If you have any questions about this agreement, please contact us via
          email namawalks@gmail.com
        </p>
      </Container>
    </BaseStructure>
  )
}

export default AboutPage
